.m_footer {
  color: #fff;
  background-color: $color05;
  padding: 10px $mobile-gutter;
  overflow: hidden;
  margin-top: 45px;
  @include for-breakpoint(3) {
    padding: 8px $desktop-gutter;
  }
  > .menu {
    float: left;
    @include rem("font-size", 18px);
    @include rem("line-height", 24px);
    font-weight: 200;
    @include for-breakpoint(3) {
      @include rem("font-size", 20px);
      @include rem("line-height", 24px);
    }
    > a {
      color: #fff;
      text-decoration: none;
      transition: color $transTime ease;
      &:hover {
        color: #ddd;
      }
    }
  }
  > .copyright {
    float: right;
    font-weight: 200;
    @include rem("font-size", 11px);
    @include rem("line-height", 24px);
    @include for-breakpoint(3) {
      @include rem("font-size", 15px);
      @include rem("line-height", 24px);
    }
  }
}

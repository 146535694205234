.m_video-container {
  position: relative;
  // padding-bottom: 56.25%; /* 16:9 */
  padding-bottom: 48.34%; /* same proportion as the other galleries */
  // padding-top: 25px;
  height: 0;
  > .preload-image {
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: #555;
    background-position: center;
    background-size: cover;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      background: #000;
      opacity: 0;
      transition: opacity $transTime ease;
    }
    &:after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      width: 50px;
      height: 50px;
      margin-top: -25px;
      margin-left: -25px;
      background: url("#{$imgsFolder}video-play.webp") no-repeat center;
      background-size: cover;
      transition: opacity $transTime ease, transform $transTime ease;
      transform: scale(1);
      opacity: 0.7;
      @include for-breakpoint(3) {
        width: 80px;
        height: 80px;
        margin-top: -40px;
        margin-left: -40px;
      }
    }
    &:hover {
      &:before {
        opacity: 0.2;
      }
      &:after {
        transform: scale(1.2);
        opacity: 1;
      }
    }
  }
  > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    z-index: 4;
  }
}

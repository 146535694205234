.m_bottom-cta {
  position: static;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-top: 0px;
  > .wrapper {
    margin: 0px auto;
    max-width: $max-width;

    > a {
      @include rem("font-size", 20px);
      @include rem("line-height", 22px);
      padding: 10px $mobile-gutter;
      color: #fff;
      text-decoration: none;
      display: block;
      font-weight: 200;
      background-color: $color02;
      transition: background-color $transTime ease;
      @include for-breakpoint(3) {
        padding: 10px $desktop-gutter;
        @include rem("font-size", 20px);
        @include rem("line-height", 22px);
      }
      &:hover {
        background-color: darken($color02, 6%);
      }
    }
  }

  & + .m_footer {
    margin-top: 5px;
  }
}

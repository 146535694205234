div.m_main-wrapper {
  max-width: 1200px;
  margin: 0px auto;
  overflow: auto;
  @media (min-width: 1200px) {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  }
  &.m_main-wrapper__clean {
    box-shadow: none;
    overflow: visible;
  }
}

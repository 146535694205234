// ***************************************************************************
// RESPONSIVE DESIGN BREAKPOINTS
// Separate with commas
// to be used in conjunction with '@mixin for-breakpoint(n)' found in sass/custom/mixins/_responsive.scss
// ***************************************************************************
$breakpoints: 320px, 500px, 764px, 1024px, 1140px, 1400px;
$max-width: 1200px;
$mobile-gutter: 30px;
$desktop-gutter: 50px;

$assetsFolder: "/assets/";
$imgsFolder: "#{$assetsFolder}imgs/";
$webfontsFolder: "#{$assetsFolder}webfonts/";
$slickFolder: "#{$assetsFolder}assets/slick/";

$transTime: 300ms;

$color01: lighten(black, 20%);
//$color02: #e13330;
$color02: #e1302d;
$color03: lighten(black, 95%); // light gray
$color04: lighten(black, 10%); // caption color
//$color05: #777; // footer color
$color05: #888;

$font-main: "HelveticaNeueLTStd", sans-serif;

// 2023 new classes
img {
  height: auto;
}
.hidden-h1 {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
}

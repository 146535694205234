.m_article-section {
  > .wrapper {
    padding: 0 $mobile-gutter;
    @include for-breakpoint(3) {
      padding: 0 $desktop-gutter;
    }
  }

  p,
  ul,
  ol {
    @include rem("font-size", 13px);
    @include rem("line-height", 19px);
    margin-bottom: 30px;
    @include for-breakpoint(3) {
      margin-bottom: 45px;
    }
  }
  p {
    &.big-size {
      @include rem("font-size", 20px);
      @include rem("line-height", 24px);
      margin-bottom: 30px;
      font-weight: 200;
      @include for-breakpoint(3) {
        margin-bottom: 30px;
      }
    }
  }
  ul {
    list-style: disc;
    margin-left: 15px;
  }
  h2,
  .h2 {
    @include rem("font-size", 20px);
    @include rem("line-height", 22px);
    font-weight: 200;
    @include for-breakpoint(3) {
      @include rem("font-size", 25px);
      @include rem("line-height", 30px);
    }
  }
  h3,
  .h3 {
    color: $color02;
    @include rem("font-size", 20px);
    @include rem("line-height", 22px);
    font-weight: 200;
    @include for-breakpoint(3) {
    }
  }
  h4,
  .h4 {
    color: $color01;
    @include rem("font-size", 20px);
    @include rem("line-height", 22px);
    font-weight: 200;
    @include for-breakpoint(3) {
    }
  }
  a {
    font-weight: bold;
    text-decoration: none;
    color: $color01;
    &:hover {
      color: $color02;
    }
  }
  span.callout {
    color: $color02;
  }
  i {
    font-style: italic;
  }

  .m_article-section--one-col {
    max-width: 805px;
    margin: 30px 0;
    @include for-breakpoint(3) {
      margin: 45px 0;
    }
  }
  .m_article-section--two-cols {
    max-width: 960px;
    margin: 10px 0 40px 0;
    @include for-breakpoint(3) {
      margin: 50px 0;
    }
    p,
    ul,
    ol {
      @include for-breakpoint(3) {
        margin-bottom: 30px;
      }
      &.no-margin {
        margin-bottom: 2px;
        @include for-breakpoint(3) {
          margin-bottom: 2px;
        }
      }
    }
    p {
      &:last-child {
        @include for-breakpoint(3) {
          margin-bottom: 0;
        }
      }
    }
    > .columns-content {
      @include for-breakpoint(3) {
        overflow: hidden;
      }
      > .column {
        @include for-breakpoint(3) {
          width: 50%;
          float: left;
          padding: 0 15px;
          &:nth-child(1) {
            padding-left: 0;
          }
          &:nth-child(2) {
            padding-right: 0;
          }
        }
      }
    }

    &.add-padding {
      max-width: none;
      > .columns-content {
        > .column {
          @include for-breakpoint(3) {
            padding-inline: 40px;
            &:nth-child(1) {
              padding-left: 0;
            }
            &:nth-child(2) {
              padding-right: 0;
            }
          }

          &.about-book {
            @include for-breakpoint(6) {
              padding-right: 116px;
            }
          }
        }
      }
    }
  }

  &.m_article-section__contact,
  &.m_article-section__terms {
    h2,
    .h2 {
      color: $color02;
      @include for-breakpoint(3) {
        @include rem("font-size", 30px);
        @include rem("line-height", 35px);
      }
    }
    .m_article-section--two-cols {
      margin: 30px 0;
      @include for-breakpoint(3) {
        margin: 50px 0;
      }
    }
  }

  &.m_article-section__contact {
    position: relative;
    border-bottom: 1px solid $color01;
    @include for-breakpoint(3) {
      border-bottom: 2px solid $color01;
    }
    p {
      &:last-child {
        @include for-breakpoint(3) {
          margin-bottom: 0;
        }
      }
      &.credits-info {
        @include for-breakpoint(3) {
          position: absolute;
          margin-bottom: 0;
          bottom: 50px;
          left: $desktop-gutter;
        }
      }
    }
    .signature {
      max-width: 180px;
      height: auto;
      display: block;
      margin: 5px 0;
    }
  }

  &.m_article-section__book{
    h2{
      margin-bottom: 5px;
    }
    a{
      text-decoration: underline;
    }
    .link-list{
      margin-bottom: 15px;
      a{
        display: inline-block;
        margin-bottom: 5px;
      }
    }
    .book-info{
      margin-bottom: 20px;
      @include for-breakpoint(2) {
        display: grid;
        grid-template-columns: 180px 1fr;
        gap: 5px;
      }
      .badge{
        text-align: center;
        @include for-breakpoint(2) {
          text-align: right;
          max-width: 239px;
        }
        img{
          max-width: 100%;
          height: auto;
        }
      }

      p {
        margin-bottom: 15px;
      }
    }
  }

  &.m_article-section__terms {
    h2,
    .h2 {
      margin-bottom: 20px;
    }
  }
}

.m_work {
  padding: 0px 0 40px 0;
  max-width: 830px;
  @include for-breakpoint(3) {
    padding: 0px 0 30px 0;
    width: 100%;
    display: table;
    table-layout: fixed;
  }
  > div {
    @include for-breakpoint(3) {
      display: table-cell;
      vertical-align: bottom;
      width: 50%;
    }
  }

  .m_work--gallery-container {
    margin-bottom: 10px;
    @include for-breakpoint(3) {
      margin-bottom: 0;
      padding-right: 15px;
    }

    > img {
      max-width: 100%;
      height: auto;
    }
  }
  .m_work--description {
    @include for-breakpoint(3) {
      padding-left: 15px;
    }
    > .year {
      @include rem("font-size", 16px);
      @include rem("line-height", 20px);
      font-weight: 600;
      color: $color01;
    }
    > .name {
      @include rem("font-size", 20px);
      @include rem("line-height", 22px);
      font-weight: 200;
      color: $color02;
      margin-bottom: 2px;
    }
    > .text {
      @include rem("font-size", 13px);
      @include rem("line-height", 19px);
      font-weight: 400;
      color: $color01;
      > a {
        color: $color01;
        font-weight: 600;
        text-decoration: none;
      }
    }
  }
}

.m_image-container {
  margin: 0;
  > img {
    display: block;
    margin: 0;
    max-width: 100%;
    width: 100%;
  }

  &.m_image-container__floating-caption {
    margin-bottom: 20px;
    @include for-breakpoint(3) {
      position: relative;
      margin-bottom: 0;
    }
    > span.caption {
      display: block;
      margin: 5px $mobile-gutter;
      width: 200;
      font-style: italic;
      @include rem("font-size", 11px);
      @include rem("line-height", 19px);
      @include for-breakpoint(3) {
        @include rem("font-size", 13px);
        position: absolute;
        margin: 5px $desktop-gutter;
        bottom: 20px;
        left: 0;
      }
      @include for-breakpoint(4) {
        bottom: 30px;
      }
    }
  }

  &.m_image-container__contact-header {
    margin-top: 40px;
    @include for-breakpoint(3) {
      margin-top: 50px;
    }
    @include for-breakpoint(4) {
      margin-top: 90px;
    }
  }
}

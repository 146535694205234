.m_slide {
  > .content {
    position: relative;
    > .image {
      display: block;
      width: 100%;
      height: auto;
    }
    > .video {
    }
    > .caption {
      color: $color04;
      font-weight: bold;
      @include rem("font-size", 11px);
      @include rem("line-height", 15px);
      @include rem("height", 45px);
      margin-top: 5px;
      padding: 0 $mobile-gutter;
      i {
        font-style: italic;
      }
      @include for-breakpoint(3) {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 7px $desktop-gutter;
        margin: 0;
        height: auto;
        background: rgba(#fff, 0.6);
        z-index: 2;
      }
    }
  }

  &.m_slide__light {
    > .content {
      > .caption {
        @include for-breakpoint(3) {
          color: #fff;
        }
      }
    }
  }
}

.m_timeline {
  padding: 70px $mobile-gutter 40px $mobile-gutter;
  min-height: 100vh;
  @include for-breakpoint(3) {
    padding: 90px 0 40px 0;
  }
  > .list-container {
    @include for-breakpoint(3) {
      white-space: nowrap;
      overflow: scroll;
    }
    .list {
      max-width: 100%;
      @include for-breakpoint(3) {
        position: relative;
        display: inline-block;
        white-space: nowrap;
        overflow: visible;
        margin: 70px 50px 0 50px;
        max-width: none;
      }
      @media screen and (min-width: 1200px) {
        margin: 70px calc(50% - 550px) 0 calc(50% - 550px);
      }
    }
  }

  .m_timeline--item {
    margin-bottom: 30px;
    @include for-breakpoint(3) {
      padding-right: 30px;
      float: left;
    }
    &:last-child {
      @include for-breakpoint(3) {
        padding-right: 0;
      }
    }
    > .gallery-container {
      margin-bottom: 10px;
      @include for-breakpoint(3) {
        margin-bottom: 30px;
        width: 240px;
      }

      > img {
        max-width: 100%;
        height: auto;
        @include for-breakpoint(3) {
          height: 370px;
          width: auto;
        }
      }
    }
    > .description {
      @include for-breakpoint(3) {
        display: block;
        max-width: 240px;
        white-space: normal;
      }
      strong {
        color: $color02;
        font-weight: 400;
      }
      > .year {
        @include rem("font-size", 16px);
        @include rem("line-height", 20px);
        font-weight: 600;
        color: $color01;
      }
      > .text {
        @include rem("font-size", 13px);
        @include rem("line-height", 19px);
        font-weight: 400;
        color: #58585b;
        > a.video-link {
          display: inline-block;
          background: $color01 url("#{$imgsFolder}video-tag.svg") center
            no-repeat;
          width: 34px;
          height: 12px;
          margin-bottom: -1px;
          &:hover {
            background-color: $color02;
          }
        }
      }
    }
    &.m_timeline--item__wide {
      > .gallery-container {
        @include for-breakpoint(3) {
          width: 480px;
        }
      }
    }
  }
}

/*
os-theme-block-dark theme for overlay scrollbar
*/
.os-theme-block-dark > .os-scrollbar {
  padding: 0 50px;
  @media screen and (min-width: 1200px) {
    padding: 0 calc(50% - 550px);
  }
}
.os-theme-block-dark > .os-scrollbar-horizontal {
  right: 16px;
  height: 16px;
  top: 0;
  bottom: auto;
}
.os-theme-block-dark.os-host-rtl > .os-scrollbar-horizontal {
  left: 16px;
  right: 0;
}
.os-theme-block-dark > .os-scrollbar-corner {
  height: 16px;
  width: 16px;
  background-color: transparent;
}
.os-theme-block-dark > .os-scrollbar > .os-scrollbar-track {
  background: transparent;
}
.os-theme-block-dark > .os-scrollbar-horizontal > .os-scrollbar-track:before {
  content: "";
  display: block;
  position: absolute;
  background: #333333;
}
.os-theme-block-dark > .os-scrollbar-horizontal > .os-scrollbar-track:before {
  left: 4px;
  right: 0;
  height: 2px;
  top: 18px;
  margin-top: -1px;
}
.os-theme-block-dark
  > .os-scrollbar
  > .os-scrollbar-track
  > .os-scrollbar-handle {
  background: transparent;
}
.os-theme-block-dark
  > .os-scrollbar-horizontal
  > .os-scrollbar-track
  > .os-scrollbar-handle {
  min-width: 46px;
  max-width: 46px;
}
.os-host-resize-disabled.os-host-scrollbar-vertical-hidden
  > .os-scrollbar-horizontal,
.os-host-rtl.os-host-resize-disabled.os-host-scrollbar-vertical-hidden
  > .os-scrollbar-horizontal {
  right: 0;
}
.os-theme-block-dark
  > .os-scrollbar
  > .os-scrollbar-track
  > .os-scrollbar-handle:before {
  //background: #e13330 !important;
  background: $color02 !important;
  content: "";
  display: block;
  height: 27px;
  position: absolute;
  top: 4px;
  bottom: 4px;
  left: 0;
  right: 0;
  width: 46px;
}
.os-theme-block-dark.os-host-scrolling
  > .os-scrollbar
  > .os-scrollbar-track
  > .os-scrollbar-handle:before {
  background: #454545;
}
/*
.os-theme-block-dark > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle:hover:before,
.os-theme-block-dark > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle.active:before {
    background: #000;
}
*/
.os-theme-block-dark
  > .os-scrollbar-horizontal
  > .os-scrollbar-track
  > .os-scrollbar-handle {
  height: 100%;
  min-width: 46px;
}
.os-theme-block-dark.os-host-transition
  > .os-scrollbar
  > .os-scrollbar-track
  > .os-scrollbar-handle:before {
  transition: background 0.3s;
}
.os-theme-block-dark.os-host-transition
  > .os-scrollbar
  > .os-scrollbar-track:before {
  transition: margin 0.3s, height 0.3s, width 0.3s, top 0.3s, right 0.3s,
    bottom 0.3s, left 0.3s;
}

.m_slideshow {
  min-height: 160px;
  img {
    display: block;
    max-width: 100%;
    height: auto;
    width: 100%;
  }

  /* Slick slider custom theme */
  .slick-loading .slick-list {
    background: #fff url("#{$slickFolder}ajax-loader.gif") center center
      no-repeat;
  }
  /* Arrows */
  .slick-prev,
  .slick-next {
    background: url("#{$imgsFolder}arrow-next.svg") center;
    background-size: cover;
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 16px;
    height: 28px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    opacity: 1;
    transition: opacity $transTime ease;

    &:hover {
      color: transparent;
      outline: none;
      opacity: 0.7;
    }
    &:focus {
      color: transparent;
      outline: none;
    }

    &.slick-disabled {
      display: none!important;
    }
  }

  &:not(.m_slideshow__book) .slick-prev {
    left: $mobile-gutter;
    display: none !important;
  }

  .slick-next {
    right: $mobile-gutter;
    @include for-breakpoint(3) {
      right: $desktop-gutter;
    }
  }

  .slick-dots {
    position: absolute;
    bottom: 58px;
    right: $mobile-gutter;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: right;
    @include for-breakpoint(3) {
      bottom: 45px;
      right: $desktop-gutter;
    }
    li {
      position: relative;
      display: inline-block;
      width: 8px;
      height: 8px;
      margin: 0 1px;
      padding: 0;
      cursor: pointer;
      @include for-breakpoint(2) {
        width: 14px;
        height: 14px;
        margin: 0 5px;
      }
      button {
        font-size: 0;
        line-height: 0;

        display: block;

        width: 8px;
        height: 8px;
        padding: 2px;

        cursor: pointer;

        color: transparent;
        border: 0;
        outline: none;
        background: transparent;
        border: 1px solid #fff;
        border-radius: 20px;
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
        @include for-breakpoint(2) {
          width: 14px;
          height: 14px;
          padding: 5px;
          border: 2px solid #fff;
        }
        &:hover,
        &:focus {
          outline: none;
          &:before {
            opacity: 1;
          }
        }
      }
      &.slick-active {
        button {
          background: #fff;
        }
      }
    }
  }

  &:not(.slick-initialized) {
    // only show the first slide before the slideshow loads
    > div {
      img {
        display: none;
      }
      &:first-child {
        img {
          display: block;
        }
      }
    }
  }

  &.m_slideshow__full {
    @include for-breakpoint(3) {
      min-height: 280px;
    }

    .slick-prev,
    .slick-next {
      margin-top: -25px; // Substract half of the caption height to align vertically the arrow on mobile.
      @include for-breakpoint(3) {
        margin-top: 0;
      }
    }
  }
  &.m_slideshow__clean {
    @include for-breakpoint(3) {
      margin-bottom: 4px;
    }
    .slick-next {
      right: 18px;
      @include for-breakpoint(3) {
        right: 18px;
      }
    }
  }
  &.m_slideshow__book {
    background-color: #f0f0f0;
    margin-bottom: 30px;
    .m_slide{
      img{
        //max-width: 1000px;
        display: block;
        margin: 0 auto;
      }
    }
    .slick-next{
      right: 4%;
      @include for-breakpoint(3) {
        aspect-ratio: 36 / 59;
        height: auto;
        width: 23px;
        right: 4%;
      }
      @include for-breakpoint(5) {
        right: 40px;
      }
    }
    .slick-prev {
      z-index: 1;
      left: 4%;
      transform: rotate(180deg) translate(0, 50%);
      @include for-breakpoint(3) {
        aspect-ratio: 36 / 59;
        height: auto;
        width: 23px;
        left: 4%;
      }
    }
    .slick-dots{
      bottom: 5px;
      right: 10%;
      @include for-breakpoint(2) {
        bottom: 10px;
      }
      @include for-breakpoint(3) {
        bottom: 20px;
        right: 5%;
      }
      @include for-breakpoint(4) {
        bottom: 30px;
      }
      @include for-breakpoint(5) {
        right: 5%;
      }
      li{
        width: 10px;
        height: 10px;
        margin: 0 4px;
        @include for-breakpoint(3) {
          //width: 16px;
          //height: 16px;
        }
        button{
          width: 10px;
          height: 10px;
          box-shadow: none;
          border: 1px solid #555;
          @include for-breakpoint(3) {
            //width: 16px;
            //height: 16px;
          }
        }
        &.slick-active {
          button{
            background-color: #555;
          }
        }
      }
    }
  }
}

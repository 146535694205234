.m_page-header {
  padding-top: 70px;
  position: relative;
  @include for-breakpoint(3) {
    padding-top: 100px;
  }
  @include for-breakpoint(4) {
    padding-top: 140px;
  }
  > .header-image {
    z-index: -1;
  }
  .m_page-header--text {
    padding: 0 $mobile-gutter;
    @include for-breakpoint(3) {
      padding: 0 $desktop-gutter;
    }
    > h1 {
      color: $color01;
      font-weight: 200;
      margin: 0;
      @include rem("font-size", 40px);
      @include rem("line-height", 60px);
      @include for-breakpoint(3) {
        @include rem("font-size", 70px);
        @include rem("line-height", 90px);
      }
    }
    > p {
      color: $color02;
      font-weight: 200;
      @include rem("font-size", 17px);
      @include rem("line-height", 20px);
      @include for-breakpoint(3) {
        @include rem("font-size", 25px);
        @include rem("line-height", 32px);
      }
      &.quote {
        &:before {
          content: "“";
          margin-left: -7px;
          @include for-breakpoint(3) {
            margin-left: -10px;
          }
        }
        &:after {
          content: "”";
        }
      }
    }
  }
  &.m_page-header__work {
    border-bottom: 1px solid $color01;
    @include for-breakpoint(4) {
      padding-top: 195px;
    }
    > .header-image {
      width: 100%;
      height: 224px;
      background-image: url("#{$imgsFolder}david-rowland-40-4-chair-model-profile-2.webp");
      background-size: contain;
      background-position: right bottom;
      background-repeat: no-repeat;
      @include for-breakpoint(3) {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 58%;
        height: 100%;
        background-position: right bottom;
      }
      @include for-breakpoint(4) {
        width: 100%;
        background-image: url("#{$imgsFolder}david-rowland-40-4-chair-model-profile.webp");
      }
    }
    .m_page-header--text {
      @include for-breakpoint(3) {
        padding-bottom: 130px;
      }
      > p {
        width: 78%;
        @include for-breakpoint(3) {
          max-width: 300px;
        }
        &:first-child {
          width: 68%;
          @include for-breakpoint(3) {
            max-width: 270px;
          }
        }
      }
    }
  }
  &.m_page-header__philosophy {
    > .header-image {
      width: 100%;
      height: 0;
      padding-bottom: 41%;
      background-size: cover;
      background-position: right bottom;
      background-repeat: no-repeat;
      background-image: url("#{$imgsFolder}david-rowland-40-4-chair-model-closeup2.webp");
      @include for-breakpoint(3) {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        padding-bottom: 0;
        background-size: contain;
        background-position: right bottom;
      }
      @include for-breakpoint(4) {
        background-size: cover;
      }
    }
    .m_page-header--text {
      @include for-breakpoint(3) {
        padding-bottom: 220px;
      }
      @include for-breakpoint(4) {
        padding-bottom: 180px;
      }
      > p {
        width: 65%;
        @include for-breakpoint(3) {
          max-width: 240px;
        }
      }
    }
  }
  &.m_page-header__book{
    background-color: #f0f0f0;
    margin-bottom: 8px;
    @include for-breakpoint(4) {
      padding-top: 0px;
    }
    .m_page-header--text {
      display: grid;
      grid-template-columns: 100%;
      gap: 10px;
      @include for-breakpoint(3) {
        grid-template-columns: 310px 1fr;
        place-content: center;
      }
      @include for-breakpoint(4) {
        grid-template-columns: 380px 1fr;
        max-width: 1140px;
      }
      p.intro{
        z-index: 2;
        color: $color01;
        @include for-breakpoint(4) {
          padding-top: 190px;
          padding-bottom: 130px;
          max-width: 310px;
        }
      }
    }
    .book-divider{
      height: 15px;
      background: white;
      position: absolute;
      bottom: -15px;
      width: 100%;
      z-index: 20;
    }
    .header-image{
      position: relative;
      @include for-breakpoint(3) {
        grid-row: span 2;
      }
      @include for-breakpoint(4) {
        padding-top: 0px;
      }
      img{
        width: 100%;
        display: block;
        max-width: 450px;
        height: auto;
        margin-left: -20px;
        @include for-breakpoint(3) {
          margin: -48px 0 0 auto;
        }
        @include for-breakpoint(4) {
          position: absolute;
          height: 760px;
          top: -30px;
          left: -40px;
          max-width: none;
          width: auto;
          margin-top: 0;
        }
      }
    }
    .quote{
      z-index: 2;
      padding-bottom: 30px;
      @include for-breakpoint(4) {
        height: 220px;
        padding-bottom: 40px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }
    }
  }
}

@import url("//hello.myfonts.net/count/32eb5d");

@font-face {
  font-family: "HelveticaNeueLTStd";
  font-weight: 200;
  font-style: normal;
  font-display: swap;
  src: url("#{$webfontsFolder}32EB5D_0_0.eot");
  src: url("#{$webfontsFolder}32EB5D_0_0.eot?#iefix")
      format("embedded-opentype"),
    url("#{$webfontsFolder}32EB5D_0_0.woff2") format("woff2"),
    url("#{$webfontsFolder}32EB5D_0_0.woff") format("woff"),
    url("#{$webfontsFolder}32EB5D_0_0.ttf") format("truetype");
}

@font-face {
  font-family: "HelveticaNeueLTStd";
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: url("#{$webfontsFolder}32EB5D_1_0.eot");
  src: url("#{$webfontsFolder}32EB5D_1_0.eot?#iefix")
      format("embedded-opentype"),
    url("#{$webfontsFolder}32EB5D_1_0.woff2") format("woff2"),
    url("#{$webfontsFolder}32EB5D_1_0.woff") format("woff"),
    url("#{$webfontsFolder}32EB5D_1_0.ttf") format("truetype");
}

@font-face {
  font-family: "HelveticaNeueLTStd";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("#{$webfontsFolder}32EB5D_2_0.eot");
  src: url("#{$webfontsFolder}32EB5D_2_0.eot?#iefix")
      format("embedded-opentype"),
    url("#{$webfontsFolder}32EB5D_2_0.woff2") format("woff2"),
    url("#{$webfontsFolder}32EB5D_2_0.woff") format("woff"),
    url("#{$webfontsFolder}32EB5D_2_0.ttf") format("truetype");
}

@font-face {
  font-family: "HelveticaNeueLTStd";
  font-weight: 600;
  font-style: italic;
  font-display: swap;
  src: url("#{$webfontsFolder}32EB5D_3_0.eot");
  src: url("#{$webfontsFolder}32EB5D_3_0.eot?#iefix")
      format("embedded-opentype"),
    url("#{$webfontsFolder}32EB5D_3_0.woff2") format("woff2"),
    url("#{$webfontsFolder}32EB5D_3_0.woff") format("woff"),
    url("#{$webfontsFolder}32EB5D_3_0.ttf") format("truetype");
}

@font-face {
  font-family: "HelveticaNeueLTStd";
  font-weight: 400;
  font-style: italic;
  font-display: swap;
  src: url("#{$webfontsFolder}32EB5D_4_0.eot");
  src: url("#{$webfontsFolder}32EB5D_4_0.eot?#iefix")
      format("embedded-opentype"),
    url("#{$webfontsFolder}32EB5D_4_0.woff2") format("woff2"),
    url("#{$webfontsFolder}32EB5D_4_0.woff") format("woff"),
    url("#{$webfontsFolder}32EB5D_4_0.ttf") format("truetype");
}

@font-face {
  font-family: "HelveticaNeueLTStd";
  font-weight: 200;
  font-style: italic;
  font-display: swap;
  src: url("#{$webfontsFolder}32EB5D_5_0.eot");
  src: url("#{$webfontsFolder}32EB5D_5_0.eot?#iefix")
      format("embedded-opentype"),
    url("#{$webfontsFolder}32EB5D_5_0.woff2") format("woff2"),
    url("#{$webfontsFolder}32EB5D_5_0.woff") format("woff"),
    url("#{$webfontsFolder}32EB5D_5_0.ttf") format("truetype");
}

html {
  font-size: 62.5%;
  // http://hugogiraudel.com/2013/03/18/ultimate-rem-mixin/

  font-family: $font-main;
  color: $color01;
}

body {
  overflow-x: hidden; // to hide the timeline overflow
  box-sizing: border-box;
  * {
    box-sizing: border-box;
    &:focus {
      outline: none;
    }
  }
}

figure {
  display: block;
  margin: 0;
}
figcaption {
}

.desktop-line-breaks {
  br {
    display: none;
    @include for-breakpoint(3) {
      display: block;
    }
  }

  &__big br {
    display: none;
    @include for-breakpoint(5) {
      display: block;
    }
  }
}
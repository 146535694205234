%nav-link {
  display: block;
  text-decoration: none;
  color: $color01;
  @include rem("font-size", 15px);
  line-height: 40px;
  transition: color $transTime ease, background $transTime ease;
  border-top: 1px solid $color01;
  padding: 0 $mobile-gutter;
  @include for-breakpoint(3) {
    display: inline-block;
    border-top: none;
    @include rem("font-size", 20px);
    line-height: 50px;
    padding: 0;
  }
  &:hover {
    color: $color02;
    background-color: $color03;
    @include for-breakpoint(3) {
      background-color: transparent;
    }
  }
}

%active-nav-link {
  color: $color02;
  &:hover {
    color: lighten($color02, 5%);
  }
}

header.m_main-header {
  position: fixed;
  width: 100%;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 10;
  background-color: #fff;
  border-bottom: 1px solid $color01;
  @include for-breakpoint(4) {
    position: absolute;
    background-color: transparent;
    border-bottom: none;
    margin-top: 19px;
  }

  > div.wrapper {
    margin: 0px auto;
    max-width: $max-width;
    font-family: $font-main;
    font-weight: 300;

    @include for-breakpoint(3) {
      padding-left: $desktop-gutter;
    }
  }

  h1.m_main-header--logo {
    margin: 0;
    @include for-breakpoint(3) {
      display: inline-block;
    }
    > a {
      @include rem("font-size", 18px);
      @extend %nav-link;
      border-top: none;
      @include for-breakpoint(3) {
        @include rem("font-size", 20px);
      }
      &:hover {
        background-color: transparent;
      }
      &.active {
        @extend %active-nav-link;
        color: $color01;
        @include for-breakpoint(3) {
          color: $color02;
        }
      }
    }
  }

  nav.m_main-header--main-nav {
    max-height: 0;
    overflow: hidden;
    transition: max-height $transTime ease;
    @include for-breakpoint(3) {
      margin-left: 0px;
      max-height: none;
      display: inline-block;
      overflow: visible;
    }
    > a {
      @extend %nav-link;
      @include for-breakpoint(3) {
        margin-left: 15px;
      }
      &.active {
        @extend %active-nav-link;
      }
    }
  }

  .m_main-header--menu-btn {
    position: absolute;
    width: 40px;
    height: 40px;
    top: 0;
    right: $mobile-gutter;
    background-color: $color02;
    color: #fff;
    transition: background $transTime ease;
    &:hover {
      background-color: darken($color02, 15%);
    }
    @include for-breakpoint(3) {
      display: none;
    }
    > .line {
      position: absolute;
      background: #fff;
      height: 2px;
      width: 24px;
      top: 50%;
      margin-top: -1px;
      left: 8px;
      &:before,
      &:after {
        content: "";
        display: block;
        position: absolute;
        background: #fff;
        height: 2px;
        width: 24px;
      }
      &:before {
        top: -8px;
      }
      &:after {
        top: 8px;
      }
    }
  }
  &.isOpen {
    nav.m_main-header--main-nav {
      max-height: 200px;
    }
  }
}

.m_home-splash {
  background-image: url("#{$imgsFolder}david-rowland-40-4-chair-model.webp");
  background-size: 290px auto;
  background-position: bottom right;
  background-repeat: no-repeat;
  height: 100vh;
  min-height: 460px;
  @media screen and (min-width: 700px) {
    background-size: 520px auto;
    min-height: 600px;
  }
  @include for-breakpoint(4) {
    min-height: 700px;
    // background-image: url('#{$imgsFolder}home-bg1.webp');
    background-size: contain;
    background-position: 100% 90%; // X Y
  }

  > div.wrapper {
    max-width: $max-width;
    margin: 0px auto;
    font-family: $font-main;
    font-weight: 200;
    padding: 0 $mobile-gutter;
    position: relative;
    top: 0;
    left: 0;
    height: 100%;
    text-align: left;
    @include for-breakpoint(3) {
      padding: 0 $desktop-gutter;
    }
  }

  .m_main-wrapper--splashtext {
    @include rem("font-size", 16.5px);
    @include rem("line-height", 20px);
    color: $color01;
    max-width: 395px;
    padding-top: 70px;
    margin-bottom: 60px;
    @include for-breakpoint(2) {
      @include rem("font-size", 18px);
      @include rem("line-height", 23px);
    }
    @include for-breakpoint(3) {
      @include rem("font-size", 23px);
      @include rem("line-height", 32px);
      position: absolute;
      margin-bottom: 0;
      left: $desktop-gutter;
      top: 180px;
    }
    @include for-breakpoint(4) {
      @include rem("font-size", 25px);
      top: 270px;
    }
  }

  .m_main-wrapper--signature {
    max-width: 125px;
    @include for-breakpoint(2) {
      max-width: 200px;
    }
    @include for-breakpoint(3) {
      position: absolute;
      top: 100px;
      max-width: 220px;
    }
    @include for-breakpoint(4) {
      top: 140px;
      max-width: 329px;
    }
    > img {
      display: block;
      max-width: 100%;
      width: 100%;
      height: auto;
    }
  }
}

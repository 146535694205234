div.m_chair-header {
  padding-top: 70px;
  padding-bottom: 0;
  position: relative;
  @media screen and (min-width: 700px) {
    padding-top: 100px;
    padding-bottom: 0;
    display: flex;
  }
  @include for-breakpoint(4) {
    padding-top: 140px;
    padding-bottom: 0;
  }
  @include for-breakpoint(5) {
    padding-top: 80px;
  }
  > div.chair-image-wrapper {
    padding-left: $mobile-gutter;
    padding-bottom: 35px;
    @media screen and (min-width: 700px) {
      padding-left: $desktop-gutter;
      flex-basis: 23%;
      display: flex;
    }
    @include for-breakpoint(4) {
      flex-basis: 24.5%;
    }
    > img {
      width: 100%;
      max-width: 160px;
      display: block;
      @media screen and (min-width: 700px) {
        max-width: none;
        align-self: flex-end;
        margin-bottom: 8%;
      }
    }
  }
  > div.chair-text {
    padding: 0 $mobile-gutter;
    @media screen and (min-width: 700px) {
      padding: 0 $desktop-gutter;
      flex-basis: 50%;
      display: table-cell;
      vertical-align: middle;
    }
    @include for-breakpoint(4) {
      flex-basis: 39%;
    }
    @include for-breakpoint(5) {
      padding-top: 7%;
    }
    > h1 {
      color: $color01;
      font-weight: 200;
      margin: 0;
      @include rem("font-size", 40px);
      @include rem("line-height", 60px);
      @include for-breakpoint(3) {
        @include rem("font-size", 50px);
        @include rem("line-height", 70px);
      }
      @include for-breakpoint(4) {
        @include rem("font-size", 70px);
        @include rem("line-height", 90px);
      }
    }
    > p {
      color: $color02;
      font-weight: 200;
      @include rem("font-size", 17px);
      @include rem("line-height", 20px);
      @media screen and (min-width: 700px) {
        margin-bottom: 15px;
      }
      @include for-breakpoint(3) {
        @include rem("font-size", 22px);
        @include rem("line-height", 28px);
      }
      @include for-breakpoint(4) {
        @include rem("font-size", 25px);
        @include rem("line-height", 32px);
      }
    }
  }
  > div.david-image {
    padding-right: $mobile-gutter;
    padding-bottom: 35px;
    @media screen and (min-width: 700px) {
      padding-right: $desktop-gutter;
      flex-basis: 27%;
      display: flex;
    }
    @include for-breakpoint(4) {
      flex-basis: 36.5%;
    }
    > img {
      width: 100%;
      max-width: 300px;
      display: block;
      @media screen and (min-width: 700px) {
        max-width: none;
        align-self: flex-end;
      }
    }
  }
}

.m_small-quote{
  margin: 0;
  @include rem("font-size", 13px);
  > p{
    color: $color02;
    font-weight: 700;
    line-height: 1.46;
    margin-bottom: 4px !important;
  }
  cite{
    font-style: italic;
    font-weight: 400;
    line-height: 1.46;
  }

  &.max-12 {
    @include for-breakpoint(3) {
      max-width: 260px;
    }
  }

  &.max-13 {
    @include for-breakpoint(3) {
      max-width: 260px;
    }
  }
}